html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
}
body {
    position: relative;
    background:#fff;
    overflow-x: hidden;
}
@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
    }
}

.page-wrapper {
    position: relative;
    margin:0 auto;
    padding:250px 0 0 0;
    width: 100%;
    max-width:1920px;
    background:#F3F6F8;
    box-shadow: 0 0 120px 0 rgba(144,152,158,0.5);

    @media screen and (max-width: 991px) {
        padding-top:125px;
    }
}

#main-content {
    min-height:55vh;
}

.container.container {
    max-width: calc(1060rem/16);
    width: 100%;
}
.container.container--full {
    max-width: calc(1920rem/16);
    width: 100%;
}
.container.container--wide {
    max-width: calc(1440rem/16);
    width: 100%;
}
.container.container--narrow {
    max-width: calc(940rem/16);
    width: 100%;
}