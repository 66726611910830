.progressbar {
    text-align: center;
    position: relative;
    margin:0 auto 2rem auto;
    width:18.75rem;

    @media screen and (min-width: 768px) {
        margin-bottom:4rem;
        width:34.125rem;
    }
}
.progressbar__step--3 .progressbar__circle:before,
.progressbar__step--2 .progressbar__circle:before {
    content:'';
    position: absolute;
    top:1.875rem;
    left: -2.5rem;
    width: 2.5rem;
    height:2px;
    background: #fff;

    @media screen and (min-width: 768px) {
        left: -7.625rem;
        width: 7.625rem;
    }
}

.progressbar__step {
    width:33.333333%;
    float:left;
    position: relative;
    text-align: center;
    font-size:1.125rem;
}
.progressbar__circle {
    display: block;
    position: relative;
    margin:0 auto .5rem auto;
    width:3.75rem;
    height:3.75rem;
    line-height:3.75rem;
    border-radius: 50%;
    background:#fff;
    color: #D7D7D7;
    box-shadow: 0 10px 20px 0 rgba(144,152,158,0.2);
}
.progressbar__txt {
    color: #D7D7D7;

    @media screen and (max-width: 767px) {
        display:none;
    }
}

/* has data */
.progressbar__step--has-data .progressbar__txt,
.progressbar__step--has-data .progressbar__txt,
.progressbar__step--has-data .progressbar__circle,
.progressbar__step--has-data .progressbar__circle {
    color: var(--color-primary);
}



/* is active */
.progressbar__step--active .progressbar__circle {
    background:var(--color-primary);
    color: #fff;
}
.progressbar__step--active .progressbar__txt {
    color:var(--color-primary);
}
.progressbar__step--has-data .progressbar__circle:before,
.progressbar__step--active .progressbar__circle:before {
    background: var(--color-primary);
}