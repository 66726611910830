/* Add your font-face rules here e.g:*/
@font-face {
    font-family: 'barlowcondensed-regular';
    src: url('/static/fonts/barlowcondensed-regular-webfont.woff2') format('woff2'),
    url('/static/fonts/barlowcondensed-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: 'barlowcondensed-semibold';
    src: url('/static/fonts/barlowcondensed-semibold-webfont.woff2') format('woff2'),
    url('/static/fonts/barlowcondensed-semibold-webfont.woff') format('woff');
    font-weight: bold;
    font-display: swap;
}
