.wysiwyg table {
    width: 100%;
    max-width: 100%;

}

.wysiwyg table > thead > tr > th,
.wysiwyg table > tbody > tr > th,
.wysiwyg table > tfoot > tr > th,
.wysiwyg table > thead > tr > td,
.wysiwyg table > tbody > tr > td,
.wysiwyg table > tfoot > tr > td {
    padding: .875rem 1.25rem;
    text-align:left;
    background-color: var(--color-white);
    border: 1px solid #F3F6F8;
    @media screen and (min-width: 768px){
        padding: 1.375rem  2rem;
    }
    @media screen and (min-width: 1440px){
        font-size:1.375rem;
    }
}

.wysiwyg table.table--small > thead > tr > th,
.wysiwyg table.table--small > tbody > tr > th,
.wysiwyg table.table--small > tfoot > tr > th,
.wysiwyg table.table--small > thead > tr > td,
.wysiwyg table.table--small > tbody > tr > td,
.wysiwyg table.table--small > tfoot > tr > td {
    padding: .625rem 1rem;
    font-size:1rem;
}
.wysiwyg table > thead > tr > th {
    color:#fff;
    border:0;
    background-color: var(--color-secondary);
    font-family: var(--font-default-bold);
}

.wysiwyg table > thead > tr > th:first-child,
.wysiwyg table > tbody > tr > th:first-child,
.wysiwyg table > tfoot > tr > th:first-child,
.wysiwyg table > thead > tr > td:first-child,
.wysiwyg table > tbody > tr > td:first-child,
.wysiwyg table > tfoot > tr > td:first-child {
    border-left: 1px solid var(--color-grey);
}

.wysiwyg table > thead > tr > th:last-child,
.wysiwyg table > tbody > tr > th:last-child,
.wysiwyg table > tfoot > tr > th:last-child,
.wysiwyg table > thead > tr > td:last-child,
.wysiwyg table > tbody > tr > td:last-child,
.wysiwyg table > tfoot > tr > td:last-child{
    border-right: 1px solid var(--color-grey);
}

.wysiwyg table > tbody > tr.has-additional-info > td {
    border:0;
    padding:0;
}

.table-responsive {
    border-radius:1.5rem;
    box-shadow: 0 10px 20px 0 rgba(144,152,158,0.6);
}

@media screen and (max-width: 767px) {
    .table-responsive {
        min-height: .01%;
        overflow-x: auto;
        width: 100%;
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        /*border:2px solid #000;*/
    }
    .table-responsive table {
        border:0;
    }
    .table-responsive > table > thead > tr > th,
    .table-responsive > table > tbody > tr > th,
    .table-responsive > table > tfoot > tr > th,
    .table-responsive > table > thead > tr > td,
    .table-responsive > table > tbody > tr > td,
    .table-responsive > table > tfoot > tr > td {
        white-space: nowrap;
    }
}