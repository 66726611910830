.welcome-teaser {
    background:#fff;
    border-radius:1.5rem;
    box-shadow: 0 10px 20px 0 rgba(144,152,158,0.2);
}
.welcome-teaser:hover,
.welcome-teaser:focus {
    box-shadow: 0 10px 20px 0 rgba(144,152,158,0.5);
}
.welcome-teaser__body {
    padding:2rem;
    position: absolute;
    left:0;
    right:0;
    top:0;

    @media screen and (min-width: 768px) {
        top:auto;
        bottom:0;
    }
}
.welcome-teaser__icon {
    position: absolute;
    top:35%;
    right:-.375rem;
    color:#F3F6F8;
    font-size:4.5rem;

    @media screen and (min-width: 768px) {
        right:-1rem;
        font-size:6.25rem;
    }
}
.welcome-teaser__line2 {
    font-family: var(--font-default-bold);
    font-size:1.875rem;
    line-height:1;
}