.embed-responsive-3by2:before {
    padding-top: 66.666666667%;
}

.embed-responsive-welcome-teaser:before {
    padding-top: 40%;

    @media screen and (min-width: 768px) {
        padding-top: 66.666666667%;
    }
}