.dropdown-menu {
    padding:0;
    margin:0;
    font-size: 1.125rem;
    border:0;
    border-radius: .5rem;
    overflow: hidden;
    box-shadow: 0 10px 20px 0 rgba(144,152,158,.5);

    @media screen and (max-width: 991px){
        padding:.5rem 0;
    }
}
.dropdown-item {
    display: block;
    padding: .75rem 1.25rem;
    color: #000;

    @media screen and (max-width: 991px){
        padding: .375rem 1.25rem;
    }
}
.dropdown-item:hover,
.dropdown-item:focus,
.dropdown-item:active {
    color: #fff;
    background-color: var(--color-primary);
}