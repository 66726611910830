.accordion {
    position: relative;
    display: block;
    overflow: hidden;
    border-radius:1.5rem;
    box-shadow: 0 10px 20px 0 rgba(144,152,158,0.6);
}

.accordion > .card.card {
    margin-bottom:0;
    border:0;
    border-bottom: 1px solid #F3F6F8 !important;
}
.accordion > .card > .card-header {
    margin: 0;
    padding:0;
    font-family: var(--font-default);
    background-color: var(--color-white);
    font-size: 1rem;
    border-bottom:0;

    @media screen and (min-width: 768px) {
        font-size: 1.25rem;
    }
    @media screen and (min-width: 1440px){
        font-size:1.375rem;
    }
}
.accordion .accordion__header-link{
    display: block;
    padding: .875rem 1.25rem;
    @media screen and (min-width: 768px){
        padding: 1.375rem  2rem;
    }
}
.accordion__header-link .accordion__toggle-icon {
    color: var(--color-primary);
}
.card-header > .accordion__header-link:not(.collapsed) {
    color:#fff;
    background: var(--color-primary);
}

.accordion__header-link:not(.collapsed) .accordion__toggle-icon {
    transform: rotate(45deg);
    transition: ease-in 0.1s;
    color: inherit;
}
.accordion .card-body {
    padding: .875rem 1.25rem;
    background:#F3F6F8;
    @media screen and (min-width: 768px){
        padding: 1.375rem  2rem;
    }
}
