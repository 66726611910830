.loading-overlay-container {
    position: relative;
    overflow: hidden;
}
.loading-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, .7);
    z-index: 10;
    text-align: center;
}

/* payment */
#payment-loading {
    z-index: 999;
}

#payment-loading .loading-overlay {
    background: rgba(255, 255, 255, .9);
}
#payment-loading .loading-center {
    position: fixed;
    left:0;
    right:0;
    top:50%;
    transform: translateY(-50%);
}