.custom-radio{
    position: relative;
    padding-left: 1.33em;
}

.custom-radio:before,
.custom-radio:after {
    content: " ";
    display: table;
}

.custom-radio:after {
     clear: both;
}

.custom-radio>label{
    cursor: pointer;
}

.custom-radio__input {
    position: absolute;
    width: calc(.875rem/16);
    height: calc(.875rem/16);
    margin: calc(-.875rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.custom-radio__box {
    position: absolute;
    left: 0;
    margin-right: calc(7rem/16);
    overflow: hidden;
    border: 1px solid #c4c6c7;
    background: #ffffff;
    width: .75em;
    height: .75em;
    margin-top: 0.25em;
    border-radius: 50%;
}
.custom-radio__box:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 37.5%;
    height: 37.5%;
    border-radius: 50%;
    background: var(--color-primary);
    visibility: hidden;
}
.custom-radio__input:checked ~ .custom-radio__box:before {
    visibility: visible;
}
.custom-radio__input:focus ~ .custom-radio__box {
    border-color: var(--color-primary);
}
.has-error .custom-radio__box {
    border-color: var(--color-danger)
}
.custom-radio__text {
    display: block;
    overflow: hidden;
}

.custom-radio__img {
    position: relative;
    top:-.2em;
    margin:0 .5rem 0 0;
}


/* inline */
.form-check-inline .form-check-input {
    position: absolute; /* override bootstrap */
}