.btn {
    display: inline-block;
    padding:1rem 1.5rem;
    font-size:1rem;
    border-radius:999px;
    border:0;
    white-space: normal;
}
.btn.btn--not-rounded {
    border-radius:0;
}
.btn.disabled {
    cursor: not-allowed;
}
.btn.btn-sm {
    padding:.625rem .75rem;
    font-size: .75rem;
}
@media screen and (min-width: 768px) {
    .btn {
        padding:.875rem 2.25rem;
        font-size:1.125rem;
    }
    .btn.btn-lg {
        padding:1rem 3rem;
        font-size: 1.5rem;
    }
}


.btn > .icon{
    position: relative;
    top:.125rem;
    line-height:1px;
    margin-right:.75rem;
    @media screen and (min-width: 768px) {
        top:.125rem;

    }
}
.btn .icon--right{
    margin-right:0;
    margin-left:.75rem;
}
.btn .icon--center{
    margin-right:0;
    margin-left:0;
}


/* primary */
.btn-primary {
    color:#fff;
    background:var(--color-primary);
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
    color:#fff;
    background:var(--color-primary-dark);
}


/* primary */
.btn-default {
    color:#fff;
    background:var(--color-secondary);
}
.btn-default:hover,
.btn-default:focus,
.btn-default:active {
    color:#fff;
    background:var(--color-default);
}