.ui-datepicker {
    z-index: 1500!important;
}
.ui-datepicker .ui-state-disabled {
    opacity: .33;
}
.ui-datepicker .has-booking {
    background: rgba(255, 165, 0, 0.2);
}
.ui-datepicker .is-fully-booked.is-fully-booked {
    background: rgba(255, 0, 0, 0.1);
}
.ui-datepicker .has-booking a,
.ui-datepicker .is-fully-booked.is-fully-booked a {
    color:inherit !important;
    background: transparent !important;
}
@media screen and (max-width: 767px) {
    .ui-datepicker {
        font-size: 1rem;
    }
}
