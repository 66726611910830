.video-js .vjs-big-play-button.vjs-big-play-button,
.play-btn{
    border-radius: 50%;
    padding: 0;
    border: none;
    font-size: 100px;
    color:#fff;
    height: 100px;
    width: 100px;
    line-height: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: transparent;
    text-align: center;
}
.play-btn {
    display: block;
}
.video-js:hover .vjs-big-play-button.vjs-big-play-button,
.play-btn:hover{
    color:var(--color-primary);
}
.video-js .vjs-big-play-button:before,
.play-btn:before {
    content: var(--icon-play);
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: 400;
    color:inherit;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.video-js .vjs-big-play-button .vjs-icon-placeholder {
    display: none;
}

