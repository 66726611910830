.form {
    width: 100%;
    margin:0 auto;
}
.form--narrow {
    max-width: 440px;
}
.alert,
.form-group {
    position: relative;
    margin-bottom:1.25rem;

    @media screen and (min-width: 768px) {
        margin-bottom:1.75rem;
    }
}

.form-group.form-group--blank {
    margin:0;
    padding:0;
}

.form-group.form-group--busitem {
    margin:0;
    box-shadow: 0 10px 20px 0 rgba(144,152,158,0.2);
    border-radius:99px;
    overflow: hidden;

    @media screen and (max-width: 767px) {
        margin:.625rem 0 .75rem 0;
    }
}

.form-control-label {
    font-size:1rem;
    color:var(--color-default);

    @media screen and (min-width: 768px) {
        font-size:1.125rem;
    }
}

.form-control-label--sm {
    font-size:.875rem;
    @media screen and (min-width: 768px) {
        font-size:1.125rem;
    }
}

.form-control {
    border-radius:99px;
    padding: 1.25rem 1.5rem 1rem 1.5rem;
    border:0;
    height:3.5rem;
    box-shadow: 0 10px 20px 0 rgba(144,152,158,0.2);
    font-size:1rem;

    @media screen and (min-width: 768px) {
        font-size:1.125rem;
    }
}

.form-control.is-disabled,
.form-control:disabled {
    cursor: not-allowed;
}

.form-control:focus {
    box-shadow: 0 10px 20px 0 rgba(144,152,158,0.33);
}

textarea.form-control {
    border-radius:1.5rem;
}

.form-control.form-control--blank {
    border-radius:0;
    box-shadow: none;
}
.form-control.form-control--sm {
    padding: .75rem 1rem .75rem 1rem;
    height:2.5rem;
    font-size:.875rem;

    @media screen and (min-width: 768px) {
        font-size:1rem;
    }
}
.form-control:disabled, .form-control[readonly] {
    background: #fff;
}
.form-control[readonly]:not(.hasDatepicker) {
    opacity: .55;
    cursor: not-allowed;
}


.form-mail-container {
    position: relative;
    color:#fff;
    padding:.5rem .5rem 1rem 1.5rem;
    border-radius:2rem;
    background:var(--color-primary);

    @media screen and (min-width: 768px) {
        padding:.5rem .5rem .5rem 1.5rem;
    }
}


.datepicker__icon {
    pointer-events: none;
    font-size: 1.25rem;
    position: absolute;
    right:1.75rem;
    top:50%;
    transform: translateY(-50%);
}