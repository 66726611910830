.order-box {
    font-size: 1.125rem;
    position: relative;
    padding:1.25rem 0 0 0;
    box-shadow: 0 10px 20px 0 rgba(144,152,158,0.2);
    border-radius:1.5rem;
    background: #fff;
    overflow: hidden;
    margin:1rem 0 2.5rem 0;

    @media screen and (min-width: 768px) {
        font-size: 1.25rem;
        padding:2.5rem 0 0 0;
    }
}
.order-box.order-box--payment {
    padding-top:.5rem;
}
.order-box__lbl {
    font-size: 1rem;
}
.order-box__bus {
    font-family: var(--font-default-bold);
    font-size: calc(24rem/16);
    text-transform:uppercase;
    @media screen and (min-width: 768px) {
        font-size: calc(30rem/16);
    }
}

.order-box__totalprice {
    font-size: calc(40rem/16);
    @media screen and (min-width: 768px) {
        font-size: calc(60rem/16);
    }

}


/* order details */
.order-box-item {
    margin:0 0 1.25rem 0;
    padding:0 1.5rem 1.25rem 1.5rem;
    border-bottom:1px solid #F3F6F8;

    @media screen and (min-width: 768px) {
        margin:0 0 2rem 0;
        padding:0 2rem 2.5rem 2rem;

    }
}
.order-box-item.order-box-item--total {
    margin:-.5rem 0 1.25rem 0;
    padding-bottom:0;
    border:0;

    @media screen and (min-width: 768px) {
        margin-top:-1rem;
    }
}
.order-box-item.order-box-item--edit:last-of-type,
.order-box-item.order-box-item--success:last-of-type {
    margin:0;
    border:0;
}



/* payment */
.payment-hl {
    padding:0 1rem;
    @media screen and (min-width: 768px) {
        padding: 0 1rem 0 1.5rem;
    }
}
.payment-item {
    margin:0;
    padding:1rem 1rem .125rem 1rem;
    border-bottom:1px solid #F3F6F8;

    @media screen and (min-width: 768px) {
        padding: 1rem 1rem .125rem 1.5rem;
    }
}