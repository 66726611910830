.main-footer {
    font-size:1rem;
    position:relative;
    @media screen and (min-width: 768px){
        font-size:1.125rem;
    }
}

.main-footer a {
    color:inherit;
}
.main-footer a:hover,
.main-footer a:focus {
    color:var(--color-primary);
}

.address-footer {
    padding:1.25rem 1rem;
    border-bottom:2px solid #F3F6F8;
    text-align: center;
    background-color:#fff;

    @media screen and (min-width: 768px){
        background-color:transparent;
        padding:2.75rem 1rem 1.25rem 1rem;
        background:url(/static/build/img/footer.png) 50% 0 no-repeat;
        background-size:cover;
    }
}

.sitebyelements {
    width: 140px;
    height:auto;
    position: relative;
    top:-.125rem;

    @media screen and (max-width: 767px){
        margin-top:.5rem;
        top:0;
    }
}

.logo-footer {
    background-color:#fff;
    padding:.5rem .5rem 1rem .5rem;
    text-align: center;
}

.list-inline-item.logo-footer__list-item {
    margin:0;
}
.logo-footer__img {
    display: block;
    width:auto;
    height:2.25rem;
    margin:1rem 1.25rem;

    @media screen and (max-width: 767px){
        height:1.875rem;
        margin:.5rem .75rem;
    }
}
.logo-footer__img--txt {
    display: block;
    width:100%;
    max-width:450px;
    height:auto;
    margin:1.75rem 1.25rem;

    @media screen and (max-width: 767px){
        max-width:300px;
    }
}
@media screen and (min-width: 768px) and (max-width: 1279px){
    .logo-footer__list-item--txt {
        display:block;
        width:100%;
    }
    .logo-footer__img {
        margin:1rem;
    }
    .logo-footer__img--txt {
        margin:1.25rem auto;
    }
}