.busitem {
    margin:0 0 2.5rem 0;
    padding:0 0 2.5rem 0;
    border-bottom:1px solid #fff;
    position: relative;
}
.busitem__bus {
    font-family: var(--font-default-bold);
    font-size: calc(24rem/16);
    text-transform:uppercase;
    @media screen and (min-width: 768px) {
        font-size: calc(30rem/16);
    }
}