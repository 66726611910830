.header {
    position: absolute;
    top:0;
    left:0;
    right:0;
    height:215px;
    background:url(/static/build/img/header.png) 50% 0 no-repeat;
    background-size:1920px 215px;
    z-index:99;
    pointer-events: none;
}
.header a, .header button {
    pointer-events: initial;
}



/* mobile */
@media screen and (max-width: 991px){
    .header {
        height:120px;
        background:url(/static/build/img/header.png) 50% -9px no-repeat;
        background-size:1152px 129px;
    }
}