.cookie-bar {
    background: var(--color-light-grey);
    color: var(--color-text-default);
    font-size: calc(12rem / 16);
    line-height: calc(18/12);
    border-top:1px solid #d4d4d4;
    z-index:999;
    box-shadow: 0 -10px 20px 0 rgba(144,152,158,0.25);

    @media screen and (min-width: 768px) {
        padding: calc(20rem / 16) calc(60rem / 16);
        align-items: center;
    }
}

.cookie-modal__title{
    font-size: calc(30rem/16);
}
.cookie-bar__detail-link {
    color: var(--color-primary);
    font-size: calc(12rem / 16);
    font-family: var(--font-default-bold);
    font-weight: 400;
    letter-spacing: calc(1.6rem / 16);
    text-decoration: none;
    text-transform: uppercase;
    position: relative;
}

.cookie-bar__detail-link:first-child{
    margin-right: calc(15rem/16);
}

.cookie-bar__detail-link+.cookie-bar__detail-link:before {
    content: '|';
    position: absolute;
    color: var(--color-default);
    left: -20px;
    top: -1px;
}

.cookie-bar__detail-link:hover {
    color: var(--color-primary);
}
.cookie-bar__detail {
    margin-top: calc(10rem / 16);
}
.cookie-bar__detail, .cookie-bar__text {
    display: block;
    font-size: calc(15rem / 16);
}
.cookie-bar__buttons {
    @media screen and (min-width: 768px){
        margin-top: 0;
    }
}
/*.cookie-bar__accept {
    font-size: calc(13rem / 16);
    line-height: calc(14/12);
    letter-spacing: calc(2rem / 16);
    font-family: var(--font-default-bold);
    font-weight: 400;
}*/
.cookie-bar h4 {
    font-size: calc(14rem / 16);
    line-height: calc(18/14);
    color: var(--color-primary);
    font-family: var(--font-default-bold);
    font-weight: 400;
    letter-spacing: 0;
    margin-bottom: calc(12rem / 16);
}