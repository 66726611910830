.wysiwyg > p,
.wysiwyg > ol,
.wysiwyg > ul,
.wysiwyg > table {
    margin-bottom: 2em;
}
.wysiwyg > p:last-child,
.wysiwyg > ol:last-child,
.wysiwyg > ul:last-child,
.wysiwyg > table:last-child {
    margin-bottom: 0;
}
.wysiwyg img {
    max-width: 100%;
    height: auto !important; /*override inline style from wysiwyg*/
}
.wysiwyg a {
    color: var(--color-primary);
    text-decoration: none;
    border-bottom:1px dashed;
}
.wysiwyg a:hover,
.wysiwyg a:focus,
.wysiwyg a:active {
    color: var(--color-primary);
    border-bottom:1px solid;
}

.title-block {
    margin-bottom:1.25rem;
    @media screen and (min-width: 768px){
        margin-bottom:2.25rem;
    }
}


/* lists */
.wysiwyg ul>li {
    margin:.375rem;
    margin-left:1rem;
    padding-left:1rem;
    list-style: none;
    position: relative;
    @media screen and (max-width: 767px) {
        margin-left:0;
    }
}
.wysiwyg ul>li:before {
    content:'';
    position: absolute;
    left:-.75rem;
    top:.5rem;
    display: block;
    width:8px;
    height:8px;
    background: var(--color-primary);
    border-radius: 100%;
}

.wysiwyg ol>li {
    margin:.375rem;
    margin-left:1rem;
    padding-left:1rem;
    @media screen and (max-width: 767px) {
        margin-left:0;
    }
}



.wysiwyg--multicol{
    @media screen and (min-width: 768px){
        column-count: 2;
        column-gap: 4rem;
    }
}